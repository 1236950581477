import {
  absolute,
  auto,
  baseline,
  block,
  bold,
  center,
  column,
  fixed,
  flex,
  inlineBlock,
  none,
  noRepeat,
  relative,
  spaceBetween,
} from 'constants/Jss';
import OpeningPatchRight from 'assets/images/test-opening-patch-right.png';
import { fade } from '@material-ui/core';

export const testPageStyles = (theme) => ({
  Content: {
    width: '100%',
    height: '100vh',
    display: flex,
    flexDirection: column,
  },
  InnerContent: {
    flexGrow: 1,
    overflowY: auto,
    position: relative,
  },
  OpeningContent: {
    backgroundImage:
      `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(0,158,221)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
      `url(${OpeningPatchRight})`,
    backgroundPosition: '-281px -160px, calc(100% + 229px) 100%',
    backgroundSize: '500px 445.066px, 500px 445.066px',
    backgroundRepeat: `${noRepeat}, ${noRepeat}`,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: none,
    },
  },
  ClosingContent: {
    backgroundImage:
      `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(0,158,221)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
      `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(185,217,136)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
    backgroundPosition: '-281px -160px, calc(100% + 221px) 100%',
    backgroundSize: '500px 445.066px, 500px 445.066px',
    backgroundRepeat: `${noRepeat}, ${noRepeat}`,
    textAlign: center,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: none,
    },
  },
  QuestionContent: {
    backgroundImage:
      `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(0,158,221)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
      `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(252,196,128)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
    backgroundPosition: '-281px -160px, calc(100% + 221px) 100%',
    backgroundSize: '500px 445.066px, 500px 445.066px',
    backgroundRepeat: `${noRepeat}, ${noRepeat}`,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: none,
    },
  },
  NoPadding: {
    padding: '0px !important',
  },
  ImageContainer: {
    display: flex,
    justifyContent: center,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down(768)]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  IntroText: {
    color: theme.palette.blackTitle,
    lineHeight: '30px',
    maxWidth: 750,
    marginLeft: auto,
    marginRight: auto,
    '&:last-child': {
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      fontSize: 14,
      lineHeight: '21px',
    },
  },
  TestFooter: {
    width: '100%',
    height: theme.mixins.testPage.footerHeight,
    minHeight: theme.mixins.testPage.footerHeight,
    backgroundColor: theme.palette.greyF2,
    border: 0,
    borderTop: `4px solid ${theme.palette.bluishGrey}`,
    display: flex,
    alignItems: center,
    justifyContent: center,
    animation: none,
    borderRadius: 0,
    margin: '0 !important',
    [theme.breakpoints.down('sm')]: {
      height: theme.mixins.testPage.mobileFooterHeight,
      minHeight: theme.mixins.testPage.mobileFooterHeight,
      position: 'sticky',
      bottom: 0,
      '&.IOs': {
        position: '-webkit-sticky',
      },
    },
    '&.Accessible': {
      backgroundColor: theme.palette.white,
    },
  },
  DoubleButtonContainer: {
    display: flex,
    alignItems: center,
    justifyContent: spaceBetween,
    paddingLeft: 170,
    paddingRight: 170,
    width: 950,
    marginLeft: auto,
    marginRight: auto,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down(768)]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  CloseButton: {
    position: absolute,
    top: theme.spacing(4),
    right: theme.spacing(7),
    display: flex,
    alignItems: center,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(2),
      right: theme.spacing(3),
    },
  },
  ProgressBarContainer: {
    height: 45,
    display: flex,
    justifyContent: center,
    alignItems: center,
    [theme.breakpoints.down('sm')]: {
      height: 16,
      marginTop: theme.spacing(8),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down(768)]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  OpeningImage: {
    width: 380,
    height: auto,
    [theme.breakpoints.down('sm')]: {
      width: 150,
    },
  },
  QuestionWithAnswersContainer: {
    maxWidth: 950,
    marginLeft: auto,
    marginRight: auto,
  },
  Question: {
    fontWeight: bold,
    fontSize: 21,
    lineHeight: '28px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '18px',
      marginBottom: theme.spacing(3),
    },
  },
  QuestionLabel: {
    display: none,
  },
  ResultBox: {
    display: flex,
    alignItems: center,
    minWidth: 250,
    [theme.breakpoints.down('sm')]: {
      minWidth: 120,
    },
  },
  Answer: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: bold,
    '&.Correct': {
      color: theme.palette.greenSuccess,
    },
    '&.Incorrect': {
      color: theme.palette.lightPink,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '18px',
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 120,
    },
  },
  ColoredResult: {
    fontFamily: 'Montserrat',
    fontSize: 40,
    fontWeight: bold,
    '&.Green': {
      color: theme.palette.greenSuccess,
    },
    '&.Pink': {
      color: theme.palette.lightPink,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  ResultDescription: {
    fontSize: 18,
    lineHeight: '30px',
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  PatchBox: {
    position: relative,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
    },
  },
  PatchResult: {
    width: '100%',
    position: absolute,
    textAlign: center,
    top: 'calc(50% - 20px)',
    color: theme.palette.white,
    fontFamily: 'Montserrat',
    fontWeight: bold,
    display: flex,
    alignItems: baseline,
    justifyContent: center,
    '& .value': {
      fontSize: 48,
    },
    '& .percentage': {
      fontSize: 18,
    },
    [theme.breakpoints.down('sm')]: {
      top: 'calc(50% - 10px)',
      '& .value': {
        fontSize: 21,
      },
      '& .percentage': {
        fontSize: 14,
      },
    },
  },
  EndTestTitle: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  StickyTimeContainer: {
    position: 'sticky',
    top: 0,
    padding: theme.spacing(2),
    zIndex: 2,
  },
  StickyTimeContainerIos: {
    position: '-webkit-sticky !important',
  },
  StickyTimeVisible: {
    boxShadow: '0px 5px 20px #00000019',
    background: 'white',
  },
  AppLoader: {
    display: none,
    justifyContent: center,
    alignItems: center,
    height: '100%',
    position: fixed,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1200,
    background: fade(theme.palette.black, 0.9),
    '&.show': {
      display: flex,
    },
  },
  CircularLoaderContainer: {
    display: inlineBlock,
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  CircularLoader: {
    display: block,
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: theme.spacing(1),
    borderRadius: '50%',
    border: `6px solid ${theme.palette.white}`,
    borderColor: `${theme.palette.white} transparent ${theme.palette.white} transparent`,
    animationName: 'lds-dual-ring',
    animationDuration: '1.2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
});
