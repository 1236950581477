import Cookies from 'js-cookie';

import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  REGISTER,
  REGISTER_FAILED,
  REGISTER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  VERIFY_EMAIL,
  VERIFY_EMAIL_FAILED,
  VERIFY_EMAIL_SUCCESS,
  SET_IS_LOGGED_IN,
  SET_EXPIRATION,
  SET_EXPIRATION_RESET,
  SET_TOKEN_EXPIRATION,
  SET_IS_STAY_LOGGED_IN,
} from './types';
import { history } from 'store/store';
import { getDashboard } from 'store/dashboard/actions';
import { getProfileDetails } from 'store/profile/actions';
import { showNotification } from 'store/common/actions';
import * as AuthService from 'services/auth';
import { setToken } from 'utils/refreshToken';
import { parseJwt } from 'utils/jwt';
import { detectLanguage } from 'utils/language';
import { SET_PROFILE_DATA } from '../profile/types';
import Settings from 'env';
import { NotificationTypes } from 'constants/Constants';

export const login = (credentials, getAuthorizedRoutes) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN,
    });

    try {
      const { access_token, expires_in } = await AuthService.login(credentials);

      const routes = await getAuthorizedRoutes(access_token);

      setToken({ token: access_token, expiresAt: expires_in });

      dispatch(setTokenExpiration(parseInt(expires_in)));

      dispatch({
        type: LOGIN_SUCCESS,
      });

      const language = detectLanguage(history.location.pathname).toLocaleLowerCase();

      history.push(`${history.location.pathname}${history.location.search}`);

      const { name, email, avatar, sub, firstLogin, firstLoginDate } = parseJwt(access_token);

      dispatch({
        type: SET_PROFILE_DATA,
        payload: {
          id: sub,
          name,
          email,
          avatar,
          firstLoginDate,
        },
      });

      dispatch(getProfileDetails({ token: access_token, language }));

      if (`/${language}` === history.location.pathname) {
        dispatch(getDashboard(language));
      }

      dispatch(showNotification({ translateKey: 'login_success', type: NotificationTypes.success }));

      return { firstLogin, routes };
    } catch (error) {
      dispatch({
        type: LOGIN_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const register = (registerInfo) => {
  return async (dispatch) => {
    dispatch({
      type: REGISTER,
    });

    try {
      const { success } = await AuthService.register(registerInfo);

      dispatch({
        type: REGISTER_SUCCESS,
      });

      return success;
    } catch (error) {
      dispatch({
        type: REGISTER_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const verifyEmail = ({ language, token }) => {
  return async (dispatch) => {
    dispatch({
      type: VERIFY_EMAIL,
    });

    try {
      const result = await AuthService.verifyEmail({ language, token });

      dispatch({
        type: VERIFY_EMAIL_SUCCESS,
      });

      return result;
    } catch (error) {
      dispatch({
        type: VERIFY_EMAIL_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const forgotPassword = (email) => {
  return async (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD,
    });

    try {
      const { success } = await AuthService.forgotPassword(email);

      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
      });

      return success;
    } catch (error) {
      dispatch({
        type: FORGOT_PASSWORD_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const resetPassword = ({ token, password }) => {
  return async (dispatch) => {
    dispatch({
      type: RESET_PASSWORD,
    });

    try {
      const { success } = await AuthService.resetPassword({ token, password });

      dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });

      return success;
    } catch (error) {
      dispatch({
        type: RESET_PASSWORD_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const setIsLoggedIn = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_LOGGED_IN,
      payload: data,
    });
  };
};

export const setExpiration = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_EXPIRATION,
      payload: data,
    });
  };
};

export const resetTimer = () => {
  return (dispatch) => {
    const token = Cookies.get(Settings.API_TOKEN);
    if (token) {
      const { iat, exp } = parseJwt(token);
      const expirationTime = exp - iat;
      dispatch({
        type: SET_EXPIRATION,
        payload: expirationTime,
      });
      dispatch({
        type: SET_EXPIRATION_RESET,
        payload: Date.now(),
      });
      dispatch({
        type: SET_TOKEN_EXPIRATION,
        payload: exp,
      });
    }
  };
};

export const setTokenExpiration = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_TOKEN_EXPIRATION,
      payload: data,
    });
  };
};

export const setIsStayLoggedIn = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_STAY_LOGGED_IN,
      payload: data,
    });
    if (!Cookies.get(Settings.API_TOKEN_STAY_IN)) {
      Cookies.set(Settings.API_TOKEN_STAY_IN, data);
    }
    if (!data) {
      Cookies.remove(Settings.API_TOKEN_STAY_IN);
    }
  };
};
