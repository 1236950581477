import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';

import { setTutorialState } from 'store/tutorial/actions';
import { setCoursePageTutorialReady, setHomePageTutorialReady, setTopicsPageTutorialReady } from 'utils/localStorage';
import { useWindowSize } from 'utils/useWindowSize';
import theme from 'theme';
import { justify, none, pointer, start, unset } from 'constants/Jss';

import { Tooltip } from './Tooltip';
import { TYPE_NAMES } from './types';

const FAST_SRCOLL = 400;
const SLOW_SRCOLL = 2000;
const TOOLTIP_MAX_WITH = 500;
const TOOLTIP_MIN_MAGIN = 18; // min 18px!

export const RunStates = {
  NOT_RUNNIG: 'NotRunning',
  WAITING: 'Waiting',
  RUNNING: 'Running',
};

export const StepIndexes = {
  TutorialHome1: 0,
  TutorialHome2: 1,
  TutorialTopics1: 2,
  TutorialTopics2: 3,
  TutorialCourse1: 4,
};

const buttonStyle = {
  cursor: pointer,
  height: 36,
  outlineOffset: 4,
  border: none,
  borderRadius: 6,
  backgroundColor: theme.palette.lightBlue,
  color: theme.palette.white,
  fontFamily: 'Montserrat',
  fontSize: 16,
};

export const Tutorial = () => {
  const { isLoggedIn, runState, stepIndex } = useSelector(({ auth, tutorial }) => ({
    isLoggedIn: auth.isLoggedIn,
    ...tutorial,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { innerWidth, screenSize } = useWindowSize(theme.breakpoints.values);
  const isMobileTooltip = innerWidth < theme.breakpoints.values.sm;
  const stepContent = (step) => {
    return {
      [TYPE_NAMES.STEP_1]: t('tutorial_home_1'),
      [TYPE_NAMES.STEP_2]: t('tutorial_home_2'),
      [TYPE_NAMES.STEP_3]: t('tutorial_topics_1'),
      [TYPE_NAMES.STEP_4]: t('tutorial_topics_2'),
      [TYPE_NAMES.STEP_5]: t('tutorial_course_1'),
    }[step];
  };

  const steps = useMemo(
    () => [
      {
        content: stepContent(TYPE_NAMES.STEP_1),
        disableBeacon: true,
        target: '.TutorialHome1',
        placement: 'top',
        spotlightPadding: { xs: 5, sm: 5, md: 10, lg: 10, xl: 10 }[screenSize],
      },
      {
        content: stepContent(TYPE_NAMES.STEP_2),
        disableBeacon: true,
        target: '.TutorialHome2',
        placement: 'top',
        spotlightPadding: { xs: 5, sm: 10, md: 10, lg: 10, xl: 10 }[screenSize],
      },
      {
        content: stepContent(TYPE_NAMES.STEP_3),
        disableBeacon: true,
        target: '.TutorialTopics1',
        placement: 'top',
        spotlightPadding: { xs: 10, sm: 10, md: 10, lg: 20, xl: 20 }[screenSize],
        offset: 20,
      },
      {
        content: stepContent(TYPE_NAMES.STEP_4),
        disableBeacon: true,
        target: '.TutorialTopics2',
        placement: 'top',
        spotlightPadding: { xs: 10, sm: 20, md: 15, lg: 15, xl: 15 }[screenSize],
        offset: 20,
      },
      {
        content: stepContent(TYPE_NAMES.STEP_5),
        disableBeacon: true,
        target: '.TutorialCourse1',
        placement: 'top',
        spotlightPadding: { xs: 35, sm: 35, md: 35, lg: 35, xl: 35 }[screenSize],
        offset: 40,
      },
    ],
    [t, screenSize]
  );

  const callback = useCallback(
    ({ action, index, type }) => {
      let nextRunState = runState;
      let nextStepIndex = index;
      switch (type) {
        case EVENTS.STEP_AFTER:
          switch (action) {
            case ACTIONS.PREV:
              nextStepIndex--;
              switch (index) {
                case StepIndexes.TutorialHome1:
                  break;
                case StepIndexes.TutorialHome2:
                  break;
                case StepIndexes.TutorialTopics1:
                  nextRunState = RunStates.WAITING;
                  break;
                case StepIndexes.TutorialTopics2:
                  break;
                case StepIndexes.TutorialCourse1:
                  nextRunState = RunStates.WAITING;
                  break;
                default:
                  break;
              }
              break;
            case ACTIONS.NEXT:
              nextStepIndex++;
              switch (index) {
                case StepIndexes.TutorialHome1:
                  break;
                case StepIndexes.TutorialHome2:
                  nextRunState = RunStates.WAITING;
                  setHomePageTutorialReady();
                  break;
                case StepIndexes.TutorialTopics1:
                  break;
                case StepIndexes.TutorialTopics2:
                  nextRunState = RunStates.WAITING;
                  setTopicsPageTutorialReady();
                  break;
                case StepIndexes.TutorialCourse1:
                  break;
                default:
                  break;
              }
              break;
            case ACTIONS.CLOSE:
              nextRunState = RunStates.NOT_RUNNIG;
              nextStepIndex = 0;
              switch (index) {
                case StepIndexes.TutorialHome1:
                case StepIndexes.TutorialHome2:
                  setHomePageTutorialReady();
                  break;
                case StepIndexes.TutorialTopics1:
                case StepIndexes.TutorialTopics2:
                  setTopicsPageTutorialReady();
                  break;
                case StepIndexes.TutorialCourse1:
                  setCoursePageTutorialReady();
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }
          break;
        case EVENTS.TOUR_END:
          setCoursePageTutorialReady();
          nextRunState = RunStates.NOT_RUNNIG;
          nextStepIndex = 0;
          if (!isLoggedIn) {
            history.push(`${history.location.pathname}?dialog=${t('url_register')}`);
          }
          break;
        default:
          break;
      }
      dispatch(setTutorialState({ runState: nextRunState, stepIndex: nextStepIndex }));
    },
    [dispatch, t, history, runState, isLoggedIn]
  );

  const locale = useMemo(
    () => ({
      back: t('tutorial_button_back'),
      close: t('tutorial_button_close'),
      last: t(isLoggedIn ? 'tutorial_button_close' : 'tutorial_button_register'),
      next: t('tutorial_button_next'),
    }),
    [t, isLoggedIn]
  );

  return (
    <Joyride
      callback={callback}
      continuous={true}
      floaterProps={{ disableAnimation: true }}
      locale={locale}
      run={runState === RunStates.RUNNING}
      scrollDuration={stepIndex === StepIndexes.TutorialCourse1 ? SLOW_SRCOLL : FAST_SRCOLL}
      scrollToFirstStep={true}
      stepIndex={stepIndex}
      steps={steps}
      styles={{
        spotlight: {
          borderRadius: 10,
        },
        tooltip: {
          maxWidth: innerWidth ? Math.min(innerWidth - TOOLTIP_MIN_MAGIN * 2, TOOLTIP_MAX_WITH) : TOOLTIP_MAX_WITH,
          width: unset,
          borderRadius: 10,
          padding: '20px 24px 20px 32px',
          marginTop: -1,
          marginBottom: -1,
        },
        tooltipContent: {
          padding: '20px 0 0 0',
          color: theme.palette.blackTitle,
          textAlign: isMobileTooltip ? start : justify,
          fontFamily: 'Montserrat',
          fontSize: 18,
          lineHeight: '23px',
        },
        tooltipFooter: {
          marginTop: 40,
        },
        buttonBack: {
          ...buttonStyle,
          marginRight: 10,
          height: isMobileTooltip ? theme.spacing(6) : buttonStyle.height,
        },
        buttonNext: {
          ...buttonStyle,
          backgroundColor: stepIndex >= steps.length - 1 ? theme.palette.lightPink : buttonStyle.backgroundColor,
          height: isMobileTooltip ? theme.spacing(6) : buttonStyle.height,
        },
        buttonClose: {
          outlineOffset: -6,
        },
        tooltipContainer: {
          textAlign: justify,
          paddingTop: theme.spacing(2.5),
        },
      }}
      tooltipComponent={Tooltip}
    />
  );
};
