import Cookies from 'js-cookie';
import Settings from '../env';

export const detectLanguage = (pathname) =>
  pathname.includes('/en/') ? 'EN' : Cookies.get(Settings.I18NEXT) ? Cookies.get(Settings.I18NEXT).toUpperCase() : 'HU';

export const getLanguageRootUrl = () =>
  window.location.origin + '/' + detectLanguage(window.location.pathname).toLowerCase();

export const getCurrentLanguage = (pathname) => detectLanguage(pathname).toLowerCase();
